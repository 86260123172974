import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import style from './style'
import Icon from '../Icon/Icon'
import Label from '../Label/Label'
import VerbalColorType from '../../helpers/types/VerbalColor.interface'
import CommonInput from '../CommonInput/CommonInput'
import CommonInputType from '../../helpers/types/CommonInput.interface'
import { IconNameType } from '../../../types'

const useStyles = makeStyles(style)

interface PropsInterface extends React.ComponentProps<'checkbox'>, Omit<CommonInputType, 'value'> {
    label?: string;
    color?: VerbalColorType;
    iconRight?: boolean;
    value?: any;
}

type IconPropsType = {
    name: IconNameType,
    color: string
}

/**
 *
 */
const Checkbox = ({
    disabled,
    label,
    children,
    color = 'primary',
    iconRight = false,
    onChange,
    checked,
    className,
    value = true,
    'data-testid': dataTestId = 'checkbox',
    ...props
}: PropsInterface): JSX.Element => {
    const styles = useStyles()
    const [isChecked, setIsChecked] = useState(checked)
    const [iconProps, setIconProps] = useState<IconPropsType>({
        color,
        name: 'checkbox-outline-blank'
    })

    const setProperColors = () => {
        const iconName = checked ? 'check-box' : 'checkbox-outline-blank'
        if (disabled) {
            setIconProps({
                name: iconName,
                color: 'neutral-200'
            })
        } else {
            setIconProps({
                name: iconName,
                color: checked ? color : 'neutral-500'
            })
        }
    }

    useEffect(setProperColors, [])
    useEffect(setProperColors, [checked])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = !isChecked
        setIsChecked(newValue)
        if (!disabled && onChange) onChange(newValue ? value : null)
    }

    const iconClasses = [
        'icon',
        iconRight ? 'icon-right' : 'icon-left'
    ].filter((x) => x).map((x) => `checkbox--${x}`).join(' ')

    return (
        <CommonInput
            {...props}
            value={isChecked}
            clearable={false}
            data-testid={dataTestId}
        >
            <div className={`${styles.checkbox} ${className || ''}`}>
                <Label disabled={disabled} data-testid={`${dataTestId}-label`}>
                    <input type="checkbox" checked={ isChecked } onChange={handleChange} disabled={disabled} value={value} data-testid={dataTestId} />
                    {iconRight && (label || children)}
                    <Icon className={iconClasses} name={iconProps.name} size={24} color={iconProps.color}/>
                    { !iconRight && (label || children) }
                </Label>
            </div>
        </CommonInput>
    )
}

/**
 *
 */
export type CheckboxType = PropsInterface

export default Checkbox
