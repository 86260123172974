/* eslint-disable @typescript-eslint/naming-convention */
import React, { useContext, useEffect, useState } from 'react'
import { TextField } from 'foundations-library/components'
import { StateSelector, ZipCodeField } from 'functional-foundations'
import { AddressType } from 'interfaces'
import { emptyAddress } from 'helpers'
import { makeStyles } from '@material-ui/core'
import { ScreenSizeContext } from 'providers'

const style = () => ({
    address: {
        '& .city-province-zip-wrapper': {
            display: 'flex',
            flexWrap: ({ screenSize }) => screenSize.mobile && 'wrap',
            marginBottom: ({ screenSize }) => screenSize.mobile ? '10px' : '20px',
            '& .city-field': {
                width: '100%'
            },
            '& .state-selector': {
                maxWidth: ({ screenSize }) => screenSize.mobile ? '205px' : '90px',
                width: '205px',
                marginLeft: ({ screenSize }) => screenSize.mobile ? 0 : '20px',
                marginTop: ({ screenSize }) => screenSize.mobile ? '10px' : undefined,
                flex: ({ screenSize }) => screenSize.mobile ? 1 : 'auto'
            },
            '& .zip-code-field': {
                width: '160px',
                minWidth: ({ screenSize }) => screenSize.mobile ? '120px' : '160px',
                maxWidth: ({ screenSize }) => screenSize.mobile ? '120px' : '160px',
                marginLeft: ({ screenSize }) => screenSize.mobile ? '10px' : '20px',
                marginTop: ({ screenSize }) => screenSize.mobile ? '10px' : undefined,
                flex: ({ screenSize }) => screenSize.mobile ? 1 : 'auto'
            }
        },
        '& .line-1-field': {
            marginTop: ({ screenSize }) => screenSize.mobile ? '10px' : '20px',
            marginBottom: ({ screenSize }) => screenSize.mobile ? '10px' : '20px'
        }
    }
})

const useStyles = makeStyles(style)

interface PropsInterface {
    value: AddressType,
    onChange(v): any;
    isValid?(v): any;
    disabled: boolean;
}

/**
 *
 */
function address ({ value, onChange, disabled = false, isValid }: PropsInterface): JSX.Element {
    const [item, setItem] = useState(value ? { ...value } : emptyAddress())
    const screenSize = useContext(ScreenSizeContext)
    const styles = useStyles({ screenSize })

    const validateAddress = () => {
        return item.line_1 && item.city && item.province && item.postal_code && item.country
    }

    const onInputChange = (prop, cleared = false) => {
        return (e) => {
            let newValue
            if (cleared) {
                newValue = ''
            } else if (e.target) {
                newValue = e.target.value
            } else {
                newValue = e
            }
            const newItemValue = { ...item }
            newItemValue[prop] = newValue
            setItem({ ...newItemValue })
        }
    }

    useEffect(() => {
        onChange(item)
        if (isValid) {
            isValid(validateAddress())
        }
    }, [item])

    return (
        <div className={styles.address}>
            <TextField
                placeholder='Billing address'
                value={item.line_1}
                onChange={onInputChange('line_1')}
                onClear={onInputChange('line_1', true)}
                disabled={disabled}
                fullWidth
                className="line-1-field"
                data-testid="line-1-input"
            />
            <div className="city-province-zip-wrapper">
                <TextField
                    placeholder='City'
                    value={item.city}
                    onChange={onInputChange('city')}
                    onClear={onInputChange('city', true)}
                    disabled={disabled}
                    className='city-field'
                    data-testid="city-input"
                />
                <StateSelector
                    countryCode={item.country}
                    value={item.province}
                    onChange={onInputChange('province')}
                    disabled={disabled}
                    className='state-selector'
                    data-testid="state-selector"
                />
                <ZipCodeField
                    countryCode={item.country}
                    value={item.postal_code}
                    onChange={onInputChange('postal_code')}
                    onClear={onInputChange('postal_code', true)}
                    disabled={disabled}
                    className='zip-code-field'
                    data-testid="zip-code-field"
                />
            </div>
        </div>
    )
}

export default address
