export default /**
eeeeeeeeeeeeeee *
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee
eeeeeeeeeeeeeee */
() => ({
    payNow: {
        marginTop: '50px',
        maxWidth: '900px',
        margin: 'auto',
        /**
         *
         */
        paddingRight: ({ screenSize }) => screenSize.mobile && '10px',
        /**
         *
         */
        paddingLeft: ({ screenSize }) => screenSize.mobile && '10px'
    },
    tabs: {
        marginBottom: '30px'
    }
})
