/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable camelcase */
import React, { useContext } from 'react'
import { Typography, Badge, Button, IconButton } from 'foundations-library/components'
import { makeStyles } from '@material-ui/core'

import Amex from '../../../util/credit-cards-images/amex.png'
import Diners from '../../../util/credit-cards-images/diners.png'
import Discover from '../../../util/credit-cards-images/discover.png'
import JCB from '../../../util/credit-cards-images/jcb.png'
import MasterCard from '../../../util/credit-cards-images/mastercard.png'
import UnionPay from '../../../util/credit-cards-images/unionpay.png'
import Visa from '../../../util/credit-cards-images/visa.png'
import { PaymentMethodType } from 'interfaces'
import { ScreenSizeContext } from 'providers'

interface CardDetailsProps {
    primaryCard: PaymentMethodType,
    changePM?: () => void
}

/**
 *
 */
const style = (t) => {
    const { colors } = t['foundations-library']
    return {
        container: {
            '& .info': {
                display: 'flex',
                justifyContent: 'space-between',
                height: '56px',
                marginBottom: '4px',
                '& .creditCard': {
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '82px',
                    height: '46px',
                    borderRadius: '5px',
                    padding: '10px',
                    marginRight: '12px',
                    border: `1px solid ${colors.neutral[50]}`
                },
                '& .creditCardInfo': {
                    display: 'flex'
                }
            }
        }
    }
}
const useStyles = makeStyles(style)

const images = {
    JCB,
    Visa,
    UnionPay,
    Discover,
    MasterCard,
    'Diners Club': Diners,
    'American Express': Amex
}
const getBrandImg = (c) => {
    if (!c) return ''
    if (c.details && c.details.brand && images[c.details.brand]) {
        return <img
            src={images[c.details.brand]}
            alt={c.details.brand}
            height={c.details && c.details.brand === 'MasterCard' ? '26' : '20'}
            data-testid={`card-img-${c.id}`}
        />
    }
    if (c.details && c.details.brand) {
        return <span>
            {c.details.brand}
        </span>
    }

    return <>&mdash;</>
}

const getBrandName = (val) => {
    if (!val) return ''
    if (val === 'American Express') return 'AMEX'
    else return val
}

const addZeroIfNeeded = (num) => {
    if (num && num < 10) {
        return `0${num}`
    }

    return num || ''
}

const renderButtonIfNeeded = (changePM, screenSize) => {
    if (!changePM) return ''

    const commonProps = {
        onClick: () => changePM(),
        color: 'primary'
    }

    if (screenSize.mobile) {
        return <IconButton name='edit' {...commonProps} variant='icon'/>
    }

    return <Button
        {...commonProps}
        size={'medium'}
        variant={'outlined'}
        style={{ marginLeft: '12px' }}
    >Change</Button>
}

/**
 *
 */
const CardDetails = ({
    primaryCard,
    changePM
}: CardDetailsProps): JSX.Element => {
    const screenSize = useContext(ScreenSizeContext)
    const classes = useStyles({ screenSize })

    return (
        <div className={classes.container}>
            <Typography data-testid='payment-method-title' variant='subtitle1' color={'secondary-500'} style={{ marginBottom: '16px' }}>Payment method</Typography>
            <div className={'info'}>
                <div className={'creditCardInfo'}>
                    <div className={'creditCard'}>
                        {getBrandImg(primaryCard)}
                    </div>
                    <div>
                        <Typography data-testid='card-info-ending' variant={screenSize.mobile ? 'subtitle2' : 'subtitle1'}>{`${getBrandName(primaryCard?.details?.brand)} ending in ${primaryCard?.details?.last4 || ''}`}</Typography>
                        <Typography data-testid='card-expiration-date' variant={screenSize.mobile ? 'body3' : 'body2'}>{`Exp. date ${addZeroIfNeeded(primaryCard?.details?.exp_month)}/${primaryCard?.details?.exp_year || ''}`}</Typography>
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {primaryCard?.status === 'primary' && <Badge size='small' data-testid='primary-pm-badge'>Primary</Badge>}
                    {renderButtonIfNeeded(changePM, screenSize)}
                </div>
            </div>
        </div>
    )
}

/**
 *
 */
export { style }

export default CardDetails
