/**
 *
 */
const style = (t) => {
    const { colors } = t['foundations-library']
    return {
        dropdownContainer: {
            position: 'relative'
        },
        dropdown: {
            '&.dropdown--full-width': {
                width: '100%'
            },
            position: 'absolute',
            padding: '10px 0px 10px 0px',
            minWidth: '200px',
            maxHeight: '200px',
            backgroundColor: 'white',
            borderRadius: '10px',
            zIndex: 1000,
            boxShadow: '0px 3px 10px 0px #00000040',
            overflowY: 'auto',
            '@media (max-width: 600px)': {
                right: 0
            }
        },
        dropdownItem: {
            height: t.withIcon ? '48px' : '44px',
            paddingLeft: '18px',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            fontWeight: 400,
            color: colors.neutral[700],
            fontSize: '13px',
            borderBottom: t.withIcon ? `1px solid ${colors.neutral[100]}` : '',
            '&:hover': {
                backgroundColor: colors.neutral[50]
            }
        },
        dropdownTitle: {
            height: '38px',
            borderBottom: `1px solid ${colors.neutral[100]}`,
            paddingLeft: '18px',
            display: 'flex',
            alignItems: 'center',
            pointerEvents: 'none',
            color: colors.neutral[500],
            fontWeight: 500,
            fontSize: '12px'
        },
        selectedItem: {
            backgroundColor: '#C9C8C84D',
            fontWeight: 700,
            color: colors.neutral[700],
            justifyContent: 'space-between'
        },
        checkbox: {
            marginRight: '9px',
            paddingTop: '7px',
            cursor: 'pointer'
        },
        dropdownDisabled: {
            backgroundColor: '#F9F8F8',
            color: colors.neutral[300],
            pointerEvents: 'none',
            cursor: 'unset'
        }
    }
}

export default style
